import React, { useEffect, useState, useRef } from "react";
// import AppHeader from "../TopBar/AppHeader";
// import SidebarDriver from "./Sidebar";
import AppHeader from "../../TopBar/AppHeader";
import SidebarDriver from "../Sidebar";
import AppLoader from "../../AppLoader";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CRow,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CAvatar,
} from "@coreui/react";
import EmptyData from "../../EmptyData";
import { CompanyAccessList } from "../../../utils/api";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import avtar from "../../../assets/images/avtar1.jpg"
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";





const AccountAccessList = () => {

    const [loader, setLoader] = useState(false);
    const [accessList, setAccessList] = useState([]);
    const [originalAccessList, setOriginalAccessList] = useState([]); // New state for original pending list
    const [search, setSearch] = useState("");

    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(3);
    const [maxPage, setMaxPage] = React.useState(3);
    const [minPage, setMinPage] = React.useState(0);

    const navigate = useNavigate();



    useEffect(() => {
        getCompanyAccessList();
    }, [])

    const getCompanyAccessList = () => {
        setLoader(true);
        CompanyAccessList().then((res) => {
            // console.log("res +++", res)

            if (res.code == 200) {
                setAccessList(res?.data);
                setOriginalAccessList(res?.data)
            }

            if (res.code == 401) {
                toast.warning(`${res.message}`, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }

            setLoader(false)
        }).catch(() => {
            setLoader(false)
        })
    }

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);

        // Filter the access list based on the search value
        const filteredAccessList = originalAccessList?.filter(
            (item) =>
                item.company_name.toLowerCase().includes(value.toLowerCase()) ||
                item.phone.toLowerCase().includes(value.toLowerCase()) ||
                item.email.toLowerCase().includes(value.toLowerCase())
        );

        // Update accessList with filtered data or reset it to original
        setAccessList(value === "" ? originalAccessList : filteredAccessList);

        // Reset current page to 1 when search changes
        setCurrentPage(1);
    };

    const filteredData = accessList
    const recordPage = 10;
    const lastIndex = currentPage * recordPage;
    const firstIndex = lastIndex - recordPage;
    const data = filteredData?.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(filteredData?.length / recordPage);
    const number = [...Array(nPage + 1).keys()]?.slice(1);


    const pageNumber = number.map((num, i) => {
        if (num < maxPage + 1 && num > minPage) {
            return (
                <>
                    <li
                        key={i}
                        className={currentPage == num ? `active_btn ` : `unactive_btn`}
                    >
                        <button onClick={() => changePage(num)}>{num}</button>
                    </li>
                </>
            );
        } else {
            return null;
        }
    });

    const handlePrePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
            if ((currentPage - 1) % pageLimit == 0) {
                setMaxPage(maxPage - pageLimit);
                setMinPage(minPage - pageLimit);
            }
        }
    };

    const handleNextPage = () => {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1);
            if (currentPage + 1 > maxPage) {
                setMaxPage(maxPage + pageLimit);
                setMinPage(minPage + pageLimit);
            }
        }
    };


    const changePage = (id) => {
        setCurrentPage(id);
    };
    let pageIncreament = null;
    if (data?.length > maxPage) {
        pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
    }



    return (
        <>
            <div>
                <div className="container-fluidd">
                    <div className="col-md-12">
                        <div>
                            <SidebarDriver />
                            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                                <AppHeader />
                                <div
                                    className="body flex-grow-1 px-3"
                                    style={{ paddingBottom: "20px" }}
                                >
                                    <div className="mb-3 d-flex ">
                                        <h1 className="heading-for-every-page">Account Access list</h1>
                                        <div className="serach-left ms-auto" id="pending-trip-search">
                                            <MDBInputGroup>
                                                <MDBInput placeholder="Search" value={search} onChange={handleSearchChange} />

                                            </MDBInputGroup></div>
                                    </div>

                                    {/* <div className="active-trip-outer"> */}
                                    {loader ? <AppLoader /> : <div className="active-trip-outer">

                                        {data?.length > 0 ?
                                            <CTable align="middle" className="mb-0" hover responsive>
                                                <CTableHead>

                                                    <CTableRow>

                                                        <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">Name</CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">Mobile No.</CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">Email</CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">Action</CTableHeaderCell>

                                                    </CTableRow>
                                                </CTableHead>
                                                <CTableBody>
                                                    {data?.length > 0 ? data?.map((item, index) => (
                                                        <CTableRow className="text-center" key={index}>
                                                            <CTableDataCell className="text-center profle-pic">
                                                                <CAvatar size="md" alt='img' src={avtar} style={{ width: 42, height: 42 }} />
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                <div>{item.company_name} </div>
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                <div>{item?.phone}</div></CTableDataCell>
                                                            <CTableDataCell><div>{item?.email}</div></CTableDataCell>
                                                            <CTableDataCell>
                                                                <div>
                                                                    <Link to={`/access-trips/${item._id}`} className="btn btn-warning">
                                                                        Manage
                                                                    </Link>
                                                                </div>
                                                            </CTableDataCell>
                                                        </CTableRow>


                                                    )) : ""}</CTableBody>
                                            </CTable> : <EmptyData />}

                                        {
                                            data?.length > 0 ?
                                                <div
                                                    className="pagination-outer"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <div
                                                        className="prev_btn"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <button onClick={() => handlePrePage()}>
                                                            Previous
                                                        </button>
                                                    </div>
                                                    <div className="previous-page">
                                                        {/* <ul>
                                                            {pageNumber}
                                                            <button className="dots_btn">
                                                                {pageIncreament}
                                                            </button>
                                                        </ul> */}

                                                        <ul>
                                                            {pageNumber.map((item, index) => (
                                                                <li key={item.id || index}>{item}</li> // Use a unique property like `item.id`
                                                            ))}
                                                            <button className="dots_btn">
                                                                {pageIncreament} {/* Ensure this also has a `key` if it's iterating */}
                                                            </button>
                                                        </ul>

                                                    </div>
                                                    <div className="next_btn">
                                                        <button onClick={() => handleNextPage()}>Next</button>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




export default AccountAccessList