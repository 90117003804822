import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilChartPie,
   cilPuzzle,
  cilSpeedometer,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'
const sideNavBar = [
  {
    component: CNavItem,
    name: 'Trips',
    to: '/past-trips',
    icon: <div className='radius-svg'>
       {/* <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    fill="none"
    viewBox="0 0 24 26"
    className='home-svg'
  >
    <path
      fill="#fff"
      d="M13.181 1.204a1.921 1.921 0 00-2.64 0L1.234 10a2.882 2.882 0 00-.901 2.097v10.702a2.882 2.882 0 002.882 2.88h2.882a2.882 2.882 0 002.882-2.882v-4.804a.96.96 0 01.961-.96h3.843a.96.96 0 01.96.96v4.804a2.882 2.882 0 002.883 2.882h2.882a2.882 2.882 0 002.882-2.882V12.095A2.883 2.883 0 0022.487 10L13.18 1.2v.004z"
    ></path>
  </svg> */}
  <svg fill="none"  width="24"
    height="26" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 32 32" >
<path d="M28,15h1c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2.8L25,10c-0.7-1.7-2.3-2.8-4.2-3l-0.6-2.5c-0.2-0.9-1-1.5-1.9-1.5h-4.4
	c-0.9,0-1.7,0.6-1.9,1.5L11.2,7C9.4,7.2,7.8,8.3,7,10l-1.3,3H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c-1.2,0.9-2,2.4-2,4v4
	c0,0.9,0.4,1.7,1,2.2V27c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-1h10v1c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-1.8c0.6-0.5,1-1.3,1-2.2v-4
	C30,17.4,29.2,15.9,28,15z M13.8,5h4.4l0.5,2h-5.4L13.8,5z M9,21H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h3c0.6,0,1,0.4,1,1S9.6,21,9,21z
	 M10.4,24l1.2-2.3c0.5-1,1.5-1.7,2.7-1.7h3.5c1.1,0,2.2,0.6,2.7,1.7l1.2,2.3H10.4z M7.5,14l1.4-3.2C9.4,9.7,10.4,9,11.6,9H12h8h0.4
	c1.2,0,2.3,0.7,2.8,1.8l1.4,3.2H7.5z M26,21h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h3c0.6,0,1,0.4,1,1S26.6,21,26,21z"/>
</svg>

  </div>
  },
  {
    component: CNavItem,
    name: 'Payment History',
    to: '/trips-payment',
    icon: <div className='radius-svg'> 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"  width="24"
    height="26">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

  </div>
  },
  {
    component: CNavItem,
    name: 'Account-Access-List',
    to: '/account-access',
    icon: <div className='radius-svg'> 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"  width="24"
    height="26">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

  </div>
  },
  {
    component: CNavGroup,
    name: 'Setting',
    to: '',
    icon: <div className='radius-svg'>
     <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg></div>,
    items: [
      {
        component: CNavItem,
        name: 'Edit Profile',
        to: '/edit-driver-data',
      }, 
      {
        component: CNavItem,
        name: 'Change Password',
        to: '/change-password',
      }, 
      
    ],
  },
  
  // {
  //   component: CNavItem,
  //   name: 'Profile',
  //   to: '/super-admin/trips/recent-trips',
  //   icon: <div className='radius-svg'> <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="26"
  //   height="25"
  //   fill="none"
  //   viewBox="0 0 26 25"
  // >
  //   <path
  //     fill="#FFD04E"
  //     d="M16.349 24.403c-2.745 0-5.132-2.267-5.132-5.13V6.025c.12-1.79-1.074-3.102-2.506-3.102-1.432 0-2.744 1.312-2.744 3.102v9.07c0 .716-.478 1.193-1.194 1.193S3.58 15.81 3.58 15.095V6.026C3.58 2.924 5.847.537 8.711.537c2.864 0 5.012 2.387 5.012 5.49v13.245c0 1.551 1.194 2.745 2.745 2.745 1.432 0 2.744-1.194 2.744-2.745v-4.176c0-.716.478-1.194 1.194-1.194s1.193.478 1.193 1.194v4.176c-.12 2.864-2.387 5.131-5.25 5.131z"
  //   ></path>
  //   <path
  //     fill="#FFD04E"
  //     d="M23.866 17.84c-.358 0-.596-.12-.835-.358l-2.744-2.744-2.745 2.744a1.154 1.154 0 01-1.67 0 1.154 1.154 0 010-1.67l3.58-3.58a1.154 1.154 0 011.67 0l3.58 3.58a1.153 1.153 0 010 1.67c-.239.239-.478.358-.836.358zM20.287 10.68a.911.911 0 01-.597-.239c-.478-.238-4.177-2.744-4.177-5.966 0-1.193.478-2.387 1.432-3.222 1.79-1.67 4.893-1.67 6.683 0 .954.835 1.432 2.029 1.432 3.222 0 3.222-3.7 5.728-4.177 5.966a.911.911 0 01-.596.24zm0-8.353c-.597 0-1.313.239-1.671.716-.477.477-.716.955-.716 1.551 0 1.313 1.432 2.745 2.387 3.46.954-.834 2.386-2.147 2.386-3.46 0-.596-.239-1.193-.716-1.551-.358-.477-1.074-.716-1.67-.716zM4.773 25a.911.911 0 01-.596-.239C3.699 24.523 0 22.017 0 18.795c0-1.194.477-2.387 1.432-3.222 1.79-1.67 4.893-1.67 6.683 0 .954.835 1.432 2.028 1.432 3.222 0 3.222-3.7 5.728-4.177 5.966a.911.911 0 01-.597.239zm0-8.353c-.596 0-1.312.238-1.67.716-.478.358-.716.954-.716 1.551 0 1.313 1.432 2.745 2.386 3.46.955-.835 2.387-2.147 2.387-3.46 0-.597-.239-1.193-.716-1.551-.358-.478-1.074-.716-1.67-.716z"
  //   ></path>
  //   <path
  //     fill="#FFD04E"
  //     d="M20.287 5.907c.716 0 1.193-.477 1.193-1.193 0-.716-.477-1.194-1.193-1.194-.716 0-1.194.478-1.194 1.194s.478 1.193 1.194 1.193zM4.773 20.227c.716 0 1.194-.478 1.194-1.194s-.478-1.193-1.194-1.193-1.193.477-1.193 1.193c0 .716.477 1.194 1.193 1.194z"
  //   ></path>
  // </svg></div>
  // },
  // {
  //   component: CNavItem,
  //   name: 'History',
  //   to: '/super-admin/trips/recent-trips',
  //   icon: <div className='radius-svg'> <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="31"
  //   height="20"
  //   fill="none"
  //   viewBox="0 0 31 20"
  // >
  //   <path
  //     fill="#FFD04E"
  //     d="M30.147 5.978c-.072-.21-.328-.374-.542-.374h-2.774c-.213 0-.469.164-.541.374-.07.205-.123.389-.159.561L24.28 1.413A2.147 2.147 0 0022.265 0H8.093c-.899 0-1.708.568-2.014 1.413L4.226 6.54a4.507 4.507 0 00-.159-.562c-.072-.21-.328-.374-.541-.374H.752c-.213 0-.47.164-.541.374-.281.818-.281 1.286 0 2.104.072.21.328.373.541.373h2.782l-.042.116c-.565.188-.974.72-.974 1.349v9.396c0 .378.306.684.684.684h3.44a.684.684 0 00.684-.684V15.99h15.706v3.327c0 .378.305.684.683.684h3.44a.684.684 0 00.684-.684V9.92c0-.629-.408-1.161-.973-1.35l-.042-.115h2.781c.214 0 .47-.163.542-.373.28-.818.28-1.286 0-2.104zM8.355 2.516H22l2.162 5.981H6.193l2.162-5.981zm.38 10.195H5.857a1.118 1.118 0 110-2.236h2.878a1.118 1.118 0 110 2.236zm15.765 0h-2.878a1.118 1.118 0 110-2.236H24.5a1.118 1.118 0 110 2.236z"
  //   ></path>
  // </svg></div>
  // },

  // {
  //   component: CNavGroup,
  //   name: 'Driver',
  //   to: '',
  //   icon: <div className='radius-svg'><svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="25"
  //   height="25"
  //   fill="none"
  //   viewBox="0 0 25 25"
  // >
  //   <path
  //     fill="#FFD04E"
  //     d="M12.5 0C5.597 0 0 5.597 0 12.5S5.597 25 12.5 25 25 19.403 25 12.5 19.403 0 12.5 0zm0 3.125c4.068 0 7.504 2.62 8.802 6.25H3.698c1.298-3.63 4.734-6.25 8.802-6.25zm0 10.938a1.562 1.562 0 110-3.125 1.562 1.562 0 010 3.124zM3.125 12.5c4.27 0 7.727 4.114 7.8 9.216-4.419-.757-7.8-4.586-7.8-9.216zm10.95 9.216c.073-5.102 3.53-9.216 7.8-9.216 0 4.63-3.381 8.46-7.8 9.216z"
  //   ></path>
  // </svg></div>,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Add New Driver',
  //       to: '/super-admin/driver/addnewdriver',
  //     }, 
  //     {
  //       component: CNavItem,
  //       name: 'All Drivers',
  //       to: '/super-admin/driver/listofdrivers',
  //     }, 
      
  //   ],
  // },
 
  // {
  //   component: CNavGroup,
  //   name: 'History',
  //   icon: <div className='radius-svg'> <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="31"
  //   height="20"
  //   fill="none"
  //   viewBox="0 0 31 20"
  // >
  //   <path
  //     fill="#FFD04E"
  //     d="M30.147 5.978c-.072-.21-.328-.374-.542-.374h-2.774c-.213 0-.469.164-.541.374-.07.205-.123.389-.159.561L24.28 1.413A2.147 2.147 0 0022.265 0H8.093c-.899 0-1.708.568-2.014 1.413L4.226 6.54a4.507 4.507 0 00-.159-.562c-.072-.21-.328-.374-.541-.374H.752c-.213 0-.47.164-.541.374-.281.818-.281 1.286 0 2.104.072.21.328.373.541.373h2.782l-.042.116c-.565.188-.974.72-.974 1.349v9.396c0 .378.306.684.684.684h3.44a.684.684 0 00.684-.684V15.99h15.706v3.327c0 .378.305.684.683.684h3.44a.684.684 0 00.684-.684V9.92c0-.629-.408-1.161-.973-1.35l-.042-.115h2.781c.214 0 .47-.163.542-.373.28-.818.28-1.286 0-2.104zM8.355 2.516H22l2.162 5.981H6.193l2.162-5.981zm.38 10.195H5.857a1.118 1.118 0 110-2.236h2.878a1.118 1.118 0 110 2.236zm15.765 0h-2.878a1.118 1.118 0 110-2.236H24.5a1.118 1.118 0 110 2.236z"
  //   ></path>
  // </svg></div>,
  //   // items: [
  //   //   {
  //   //     component: CNavItem,
  //   //     name: 'Add New Vehicle',
  //   //     to: '/super-admin/vehicle/addnewvehicle',
        
  //   //   },
  //   //   {
  //   //     component: CNavItem,
  //   //     name: 'All Vehicles',
  //   //     to: '/super-admin/vehicle/viewallvehicle',
        
  //   //   },
      
  //   // ],
  // },

]

export default sideNavBar;
