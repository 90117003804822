

import React, { useEffect, useState } from "react";
import AppHeader from "../../TopBar/AppHeader";
import { useNavigate, useParams } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { addSuperAdmin, editSuperAdmin, getSuperAdmin } from "../../../utils/api";
import { toast } from "react-toastify";
import SuperAdminSideBar from "../Sidebar/SideBar";
import { ClipLoader } from "react-spinners";

const AddSuperAdmins = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);

  const initialValues = { first_name: "", last_name: "", email: "", phone: "" };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().required("First Name is required"),
    last_name: Yup.string().trim().required("Last Name is required"),
    email: Yup.string().trim().email().required("Email Address is required"),
    phone: Yup.string().trim().matches(/^[0-9]+$/, "Must be only digits").required("Phone Number is required")
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
     
      setSubmitLoader(true)

      if (id) {
        editSuperAdmin({
          phone: values.phone,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email
        }, id).then((res) => {
          if (res?.data?.code === 200) {
            toast.success(`SuperAdmin updated successfully`, {
              position: "top-right",
              autoClose: 1000,
            });
            navigate("/super-admin/all-super-admin")
          } else {
            toast.warning(`${res.data.message}`, {
              position: "top-right",
              autoClose: 1000,
            });
          }
        }).finally(() => {
          setSubmitLoader(false)
        });
      } else {
        addSuperAdmin({
          phone: values.phone,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email
        }).then((res) => {
          if (res?.data?.code === 200) {
            toast.success(`SuperAdmin added successfully`, {
              position: "top-right",
              autoClose: 1000,
            });
            navigate("/super-admin/all-super-admin")
          } else {
            toast.warning(`${res.data.message}`, {
              position: "top-right",
              autoClose: 1000,
            });
          }
        }).finally(() => {
          setSubmitLoader(false)
        });
      }

    },
  });

  const handleMobile = (event, max) => {
    const pattern = /^[0-9]+$/;
    if (
      event.key === "Backspace" ||
      event.key === "Enter" ||
      event.key === "Tab" ||
      event.key === "Shift" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      formik.setFieldValue(event.target.name, event.target.value);
      formik.setFieldTouched(event.target.name, true);
    } else {
      let value = event.target.value.toString();
      if (value.length > max) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        if (!pattern.test(event.key)) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          formik.setFieldValue(event.target.name, event.target.value);
          formik.setFieldTouched(event.target.name, true);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getSuperAdmin(id).then((res) => {
        if (res.data.code == 200) {
          formik.setValues({
            first_name: res.data.data.first_name,
            last_name: res.data.data.last_name,
            email: res.data.data.email,
            phone: res.data.data.phone
          })
        } else {
          toast.error(`${res.data.message}`, {
            position: "top-right",
            autoClose: 1000,
          });
          navigate("/super-admin/all-super-admin")
        }
      })
    }
  }, [id])

  return (
    <>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <SuperAdminSideBar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div
                className="body flex-grow-1 px-3"
                style={{ paddingBottom: "20px" }}
              >
                <h1 className="heading-for-every-page">
                  {id ? "Edit Super Admin" : "Add Super Admin"}
                </h1>
                <div className="active-trip-outer" id="fare_management_page">
                  <div className="trips-head d-flex justify-content-between">
                  </div>
                  <CRow>
                    <CCol xs={12}>
                      <CCard className="mb-4">
                        <CCardBody>
                          <form
                            onSubmit={formik.handleSubmit}
                            noValidate
                            className="row g-3"
                          >
                            <CCol md={6}>
                              <CFormLabel htmlFor="inputfname">
                                First Name<span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormInput
                                id="f_name"
                                {...formik.getFieldProps("first_name")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.first_name &&
                                      formik.errors.first_name,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.first_name &&
                                      !formik.errors.first_name,
                                  }
                                )}
                                name="first_name"
                                autoComplete="off"
                              />
                              {formik.errors.first_name &&
                                formik.touched.first_name ? (
                                <div className="text-danger">
                                  {formik.errors.first_name}
                                </div>
                              ) : null}
                            </CCol>
                            <CCol md={6}>
                              <CFormLabel htmlFor="inputlname">
                                Last Name<span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormInput
                                id="l_name"
                                {...formik.getFieldProps("last_name")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.last_name &&
                                      formik.errors.last_name,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.last_name &&
                                      !formik.errors.last_name,
                                  }
                                )}
                                name="last_name"
                                autoComplete="off"
                              />
                              {formik.errors.last_name &&
                                formik.touched.last_name ? (
                                <div className="text-danger">
                                  {formik.errors.last_name}
                                </div>
                              ) : null}
                            </CCol>
                            <CCol md={6}>
                              <CFormLabel htmlFor="inputmailaddress">
                                Email Address<span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormInput
                                id="email_address"
                                {...formik.getFieldProps("email")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.email &&
                                      formik.errors.email,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.email &&
                                      !formik.errors.email,
                                  }
                                )}
                                name="email"
                                autoComplete="off"
                              />
                              {formik.errors.email && formik.touched.email ? (
                                <div className="text-danger">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </CCol>

                            <CCol md={6}>
                              <CFormLabel htmlFor="inputphnnum">
                                Phone Number<span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormInput
                                aria-label="phone number"
                                onKeyDown={(e) => {
                                  handleMobile(e, 17);
                                }}
                                {...formik.getFieldProps("phone")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.phone &&
                                      formik.errors.phone,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.phone &&
                                      !formik.errors.phone,
                                  }
                                )}
                                name="phone"
                                autoComplete="off"
                              />
                              {formik.errors.phone && formik.touched.phone ? (
                                <div className="text-danger">
                                  {formik.errors.phone}
                                </div>
                              ) : null}
                            </CCol>
                            <CCol md={12}>
                              <div
                                className="d-flex justify-content-center"
                                style={{ marginTop: "40px" }}
                              >
                                <CButton type="submit" className="submit-btn">
                                  {submitLoader ?
                                    <ClipLoader color="#000000" /> : "Submit"}
                                </CButton>
                                <CButton
                                  onClick={() =>
                                    navigate(
                                      "/super-admin/all-super-admin"
                                    )
                                  }
                                  type="button"
                                  className="cancel-btn"
                                >
                                  Cancel
                                </CButton>
                              </div>
                            </CCol>
                          </form>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSuperAdmins;