import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
  CModal,
  CModalBody,
} from "@coreui/react";
import DatePicker from "react-datepicker";
import { MDBCol, MDBRow, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  addLinkTrip,
  checkCompanyId,
  companyId,
  getCompanyById,
  getFare,
  getFareById,
  getTripById,
  getVehicleType,
  tripsUpdate,
} from "../../utils/api";
import { toast } from "react-toastify";
import sessionExp from "../../assets/images/session-expired.png";
import backtotaxi from "../../assets/images/taxi.png";
import { isValidDate } from "../../utils/helpingFunction";
import { useState, useEffect } from "react";
import * as geolib from "geolib";
import SuccessIcon from "../../assets/images/svg-check.svg";

const Newvisits = () => {
  const location = new URLSearchParams(useLocation().search);
  const id = location.get("company_id");
  const [step, setStep] = useState(1);
  const [fares, setFares] = useState(null);
  const [selectedFare, setSelectedFare] = useState(null);
  const booking = useParams();
  const [visible, setVisible] = useState(false);
  console.log("pending id", id);
  const navigate = useNavigate();
  const [refreshPrice, setRefreshPrice] = useState(false);
  const [price, setPrice] = useState(0);
  const [pickupDate, setpickupDate] = useState(Date.now());
  const [passengers, setPassengers] = useState([]);
  const [vehicle, setVehicle] = useState();
  const [currentTime, SetCurrentTime] = useState({
    hour: new Date().getHours(),
    minute: new Date().getMinutes() + 1,
  });
  const priceCalculator = () => {
    console.log("funtion running for price calculation")
    let distance = null;
    console.log("🚀 ~ priceCalculator ~ inputData?.trip_to?.log:", inputData?.trip_to?.log)
    console.log("🚀 ~ priceCalculator ~ inputData?.trip_from?.log:", inputData?.trip_from?.log)
    if (inputData?.trip_from?.log && inputData?.trip_to?.log) {
      distance = (
        geolib.getDistance(
          {
            latitude: inputData?.trip_from?.lat,
            longitude: inputData?.trip_from?.log,
          },
          {
            latitude: inputData?.trip_to?.lat,
            longitude: inputData?.trip_to?.log,
          }
        ) / 1000
      ).toFixed(2);
    }
    console.log("distance is from priceCalculator", distance);
    console.log("🚀 ~ priceCalculator ~ selectedFare:", selectedFare)
    if (distance && selectedFare) {
      setPrice((distance * selectedFare?.vehicle_fare_per_km).toFixed(2));
    } else {
      setPrice(0);
    }
  };
  const [selectedFrom, setSelectedFrom] = useState(true);
  const [selectedTo, setSelectedTo] = useState(false);
  const [inputData, setInputData] = useState({
    vehicle: "",
    trip_from: { address: "", lat: null, log: null },
    trip_to: { address: "", lat: null, log: null },
    pick_up_date: new Date(),
    passenger_detail: [],
  });
  const [hotel, setHotel] = useState(null);
  const [errors, setErrors] = useState({
    vehicle: null,
    trip_from: null,
    trip_to: null,
    pick_up_date: null,
    passenger_detail: [],
  });
  const [inputs, setInputs] = useState([
    // { name: "", email: "", phone: "", address: "" },
  ]);
  const [tripFrom, setTripFrom] = useState("");
  const [tripFromCoordinates, setTripFromCoordinates] = useState(null);
  const [tripTo, setTripTo] = useState("");
  const [tripToCoordinates, setTripToCoordinates] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSelectTripFrom = async (selectedAddress) => {
    try {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        setErrors({ ...errors, trip_from: null });
        const newInputData = { ...inputData };
      inputData.trip_from.address = selectedAddress;
      inputData.trip_from.lat = latLng.lat;
      inputData.trip_from.log = latLng.lng;
      setInputData(newInputData);
      setTripFrom(selectedAddress);
      setTripFromCoordinates(latLng);
      setRefreshPrice(!refreshPrice);
      setSelectedFrom(true);

        // Ensure inputData.trip_from is initialized and update it
        // const newInputData = {
        //     ...inputData,
        //     trip_from: {
        //         ...(inputData.trip_from || {}), // Ensure trip_from is an object
        //         address: selectedAddress,
        //         lat: latLng.lat,
        //         lng: latLng.lng, // corrected to lng
        //     },
        // };

        // setInputData(newInputData);
        // setTripFrom(selectedAddress);
        // setTripFromCoordinates(latLng);
        // setRefreshPrice(!refreshPrice);
        // setSelectedFrom(true);
    } catch (error) {
        console.error("Error:", error);
    }
};


  const handleSelectTripTo = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setErrors({ ...errors, trip_to: null });
      const newInputData = { ...inputData };
      inputData.trip_to.address = selectedAddress;
      inputData.trip_to.lat = latLng.lat;
      inputData.trip_to.log = latLng.lng;
      setInputData(newInputData);
      setTripTo(selectedAddress);
      setTripToCoordinates(latLng);
      setRefreshPrice(!refreshPrice);
      setSelectedTo(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function customSetHours(date, hour) {
    if (date instanceof Date) {
      const newDate = new Date(date);
      newDate.setHours(hour);
      return newDate;
    } else {
      throw new Error("Invalid Date object");
    }
  }
  function customSetMinutes(date, minute) {
    if (date instanceof Date) {
      const newDate = new Date(date);
      newDate.setMinutes(minute);
      return newDate;
    } else {
      throw new Error("Invalid Date object");
    }
  }

  useEffect(() => {
    checkCompany()
  }, []);

  const calculatefate = (userId) => {
    getVehicleType().then((res) => {
      console.log(res.result, "vehicleType");
      if (res?.code === 200) {
        setVehicle(res.result);
        const vehicleFromApi = res.result;
        getFareById(userId).then((res) => {
          console.log(res?.result, "fares");
          if (res?.code === 200) {
            const fareFromApi = res?.result;
            setFares(res?.result);
            const newVehicle = [];
            vehicleFromApi.forEach((item)=>{
              console.log("res2.result",fareFromApi)
              fareFromApi.forEach(fare => {
                if(fare.vehicle_type == item.name){
                  newVehicle.push(item);
                }
              })
            })
            setVehicle(newVehicle);
          }
        });
      }
    });
  }

  const formValidation = (inputs) => {
    const data = [...inputs];
    var re = /\S+@\S+\.\S+/;
    const phoneRegex = /^[0-9]{10}$/;
    let valid = true;
    for (let index = 0; index < data?.length; index++) {
      const element = data[index];
      if (data[index].name == "") {
        data[index].nameCheck = "Name required";
        data[index].nameLengthCheck = "";
        valid = false;
      } else if (data[index].name?.length < 3) {
        data[index].nameLengthCheck = "Please enter valid name";
        data[index].nameCheck = "";
        valid = false;
      } else {
        data[index].nameCheck = "";
        data[index].nameLengthCheck = "";
        valid = true;
      }

      if (data[index].email == "") {
        data[index].emailCheck = "Email required";
        data[index].emailFormat = "";
        valid = false;
      } else if (!re.test(data[index].email)) {
        data[index].emailFormat = "Invalid Email";
        data[index].emailCheck = "";
        valid = false;
      } else {
        data[index].emailCheck = "";
        data[index].emailFormat = "";
        valid = true;
      }
      if (data[index].phone == "") {
        data[index].phoneCheck = "Phone required";
        data[index].phoneLengthCheck = "";
        valid = false;
      } else if (!phoneRegex.test(data[index].phone)) {
        data[index].phoneCheck = "Enter only digit";
        data[index].phoneLengthCheck = "";
        valid = false;
      } else if (
        data[index].phone?.length < 7 &&
        data[index].phone?.length > 16
      ) {
        data[index].phoneLengthCheck = "Please enter valid phone number";
        data[index].phoneCheck = "";
        valid = false;
      } else {
        data[index].phoneCheck = "";
        data[index].phoneLengthCheck = "";
        valid = true;
      }
      if (data[index].address == "") {
        data[index].addressCheck = "Address required";
        data[index].addressLengthCheck = "";
        valid = false;
      } else if (
        data[index].address?.length < 7 &&
        data[index].address?.length > 16
      ) {
        data[index].addressLengthCheck = "Please enter valid address";
        data[index].addressCheck = "";
        valid = false;
      } else {
        data[index].addressCheck = "";
        data[index].addressLengthCheck = "";
        valid = true;
      }
    }

    setInputs(data);
    return valid;
  };
  const [companyName, setCompanyName] = useState("");
  const [expired, setExpired] = useState(false);
  const geteditid = (userId) => {
    setLoading(true);
    getCompanyById(userId)
    .then((res) => {
      console.log("company id", res);
      if (res?.code == 200) {
        if(!res.result.status){
          throw new Error("hotel status is blocked")
        }
        setHotel(res?.result)
        console.log("hotel location ++++++>>>>>>>",res?.result)
        const newInputData = {...inputData};
        newInputData.trip_from = res?.result?.hotel_location;
        console.log("🚀 ~ .then ~ newInputData:", newInputData)
        setInputData(newInputData)
        // setTripFrom(res?.result?.hotel_location.address)
        if(!res.result.status){
          navigate("/")
        }
      }
      else {
        setExpired(true)
      }
    })
    .catch((error) => {
      console.log(error);
      setExpired(true)
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    });
  };

  const adddata = () => {
    let id = id;
    let data = inputData;
    console.log(data, "data after submit");
    let valid = true;
    let newErrors = { ...errors };
    const errorRes = formValidation(inputs);
    console.log("data beafore vehicle", data);
    if (
      !data.trip_from.lat ||
      !data.trip_from.log ||
      data.trip_from.address?.length < 1 ||
      !selectedFrom
    ) {
      console.log("enter valid trip from");
      valid = false;
      newErrors.trip_from = "Please select valid trip from address";
    }
    if (
      !data.trip_to.lat ||
      !data.trip_to.log ||
      data.trip_to.address?.length < 1 ||
      !selectedTo
    ) {
      valid = false;
      newErrors.trip_to = "Please select valid trip to address";
    }
    if (data.vehicle?.length < 1) {
      console.log(
        "🚀 ~ file: BookingStaffForm.jsx:310 ~ adddata ~ data.vehicle:",
        data.vehicle
      );
      valid = false;

      newErrors.vehicle = "Please select valid vehicle type";
    }
    if (inputData.pick_up_date?.length < 1) {
      valid = false;
      newErrors.pick_up_date = "Please select valid pick-up date";
    }
    if (!valid) {
      setErrors(newErrors);
      return console.log(errors);
    }
    data.passenger_detail = inputs;
    console.log("data before api", data);
    if (errorRes) {
      console.log("data sdfjaslfj", data);
      data.vehicle_type = data.vehicle;
      delete data.vehicle;
      data.pickup_date_time = data.pick_up_date;
      delete data.pick_up_date;
      data.created_by = id;
      setStep(2);
    } else {
      toast.warning("Please Enter Passenger Detail", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };


  const setFareOnVehicleType = (vehicle_type) => {
    fares.forEach((fare) => {
      if (fare.vehicle_type == vehicle_type) {
        setSelectedFare(fare);
        console.log("selectecd fare is", fare);
      }
    });
  };
  useEffect(priceCalculator, [refreshPrice]);

  const loginSchema = Yup.object().shape({
    fname: Yup.string()
      .min(3, "Minimum 3 characters ")
      .max(50, "Maximum 50 characters ")
      .trim("White space is not allowed")
      .required("First name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .trim("White space is not allowed")
      .min(3, "Minimum 3 characters ")
      .max(50, "Maximum 50 characters ")
      .required("Email is required"),
    phoneNo: Yup.string()
      .min(7, "Phone number must be greater than 7")
      .max(16, "Phone number not be greater than 17")
      .trim("White space is not allowed")
      .required("Phone Number is required"),
    comment: Yup.string(),
  });

  const initialValues = {
    phoneNo: "",
    fname: "",
    email: "",
    comment: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log("values", values);
      setStep(4);
    },
  });

  const submitFormData = () => {
    setLoading(true);
    companyId({
      company_id: id,
      trip_from: {
        log: inputData?.trip_from?.log,
        lat: inputData?.trip_from?.lat,
      },
      trip_to: {
        log: inputData?.trip_to?.log,
        lat: inputData?.trip_to?.lat,
      },
      pickup_date_time: inputData.pick_up_date,
      vehicle_type: inputData.vehicle_type,
      passengerCount: inputData.passenger,
      name: formik.values.fname,
      email: formik.values.email,
      phone: formik.values.phoneNo,
      comment: formik.values.comment,
      price: price,
    })
      .then((res) => {
        console.log("submit company data", res);
        if (res?.data?.code == 200) {
          setVisible(true);
          setTimeout(() => {
            setVisible(false);
            setLoading(false);
            setStep(1);
            formik.resetForm();
            setTripFrom("");
            setPrice("");
            setTripTo("");
            setInputData({
              vehicle_type: "",
              trip_from: {
                address: "",
                lat: null,
                log: null,
              },
              trip_to: {
                address: "",
                lat: null,
                log: null,
              },
              pick_up_date: new Date(),
              passenger: "",
            });
          }, 3000);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const checkCompany = () => {
    setLoading(true)
    checkCompanyId(id)
      .then((res) => {
        console.log("check company", res);

        if(res?.data?.code !== 200){
          navigate("/unauthorized")
          setLoading(false)
        }else if(res?.data?.code == 200){
          const userId = res?.data?.result.user_id
          console.log("success company id", userId)
          geteditid(userId);
          calculatefate(userId)
        }
        else{
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div>
      <div
        className="main-page"
        style={{ height: "100%", padding: "20px 0px", background: "#fdbd1b" }}
      >
        <div className="container-outer" id="expired_outer">
          {expired === true ? (
            <div>
              <div className="link_expired">
                <div className="img_outer">
                  {" "}
                  <img src={sessionExp} />
                </div>
                <div className="link_text">
                  <strong>Something bad happend</strong>
                  <br />
                  <p>
                    {" "}
                    The invitation link has expired. Please request a new link.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {step === 1 && (
                <section className="booking-section-form">
                  <div
                    className="booking-banner"
                    id="bookimg-header"
                    style={{
                      backgroundColor: "pink",
                    }}
                  >
                    <div className="row booking-content col-md-12">
                      <div className="banner-inner-text col-md-6">
                        <h3>1. Calculate Fare </h3>

                        <CRow>
                          <CCol xs={12}>
                            <CCard>
                              <CCardBody>
                                <CForm className="row g-3">
                                  <CCol>
                                    <CFormLabel htmlFor="inputvehicletype">
                                      Vehicle Type{" "}
                                      <span className="asterisk-mark">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                      name="vehicle"
                                      onChange={(data) => {
                                        console.log(
                                          "🚀 ~ BookingStaffForm ~ data:",
                                          data
                                        );

                                        setFareOnVehicleType(data.target.value);
                                        setInputData({
                                          ...inputData,
                                          vehicle: data.target.value,
                                        });
                                        if (data.target.value < 1) {
                                          setErrors({
                                            ...errors,
                                            vehicle: "Please select vehicle",
                                          });
                                        } else {
                                          setErrors({
                                            ...errors,
                                            vehicle: null,
                                          });
                                        }
                                        setRefreshPrice(!refreshPrice);
                                      }}
                                    >
                                      <option value={""} default>
                                        Select vehicle type
                                      </option>
                                      {vehicle?.map((e, i) => {
                                        return (
                                          <>
                                            <option value={e.name}>
                                              {e.name}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </CFormSelect>
                                    {errors.vehicle && (
                                      <span
                                        style={{ color: "red" }}
                                        className="text-danger"
                                      >
                                        {errors.vehicle}
                                      </span>
                                    )}
                                  </CCol>

                                  <CCol md={6}>
                                    <CFormLabel htmlFor="inputpickupdate">
                                      Pickup Date and Time
                                      <span className="asterisk-mark">*</span>
                                    </CFormLabel>
                                    <br />
                                    <DatePicker
                                      selected={new Date(pickupDate)}
                                      className="form-control"
                                      showTimeSelect
                                      timeIntervals={5}
                                      // minTime={customSetHours(customSetMinutes(new Date(), currentTime.minute), currentTime.hour)}
                                      minTime={
                                        new Date(pickupDate).setHours(
                                          0,
                                          0,
                                          0,
                                          0
                                        ) == new Date().setHours(0, 0, 0, 0)
                                          ? new Date()
                                          : new Date().setHours(0, 0, 0, 0)
                                      }
                                      maxTime={customSetHours(
                                        customSetMinutes(new Date(), 59),
                                        23
                                      )}
                                      dateFormat="MM/dd/yyyy hh:mm a"
                                      minDate={new Date()}
                                      onChange={(data) => {
                                        console.log(
                                          "🚀 ~ BookingStaffForm ~ data:",
                                          data
                                        );

                                        setpickupDate(new Date(data));
                                        setInputData({
                                          ...inputData,
                                          pick_up_date: new Date(data),
                                        });
                                        if (data < 1 || !isValidDate(data)) {
                                          setErrors({
                                            ...errors,
                                            pick_up_date:
                                              "Please add valid date for pickup date",
                                          });
                                        } else {
                                          setErrors({
                                            ...errors,
                                            pick_up_date: null,
                                          });
                                        }
                                      }}
                                    />
                                    {errors.pick_up_date && (
                                      <span
                                        style={{ color: "red" }}
                                        className="text-danger"
                                      >
                                        {errors.pick_up_date}
                                      </span>
                                    )}
                                  </CCol>

                                  <CCol xs={6}>
                                    <CFormLabel htmlFor="inputtripfrom">
                                      Trip From
                                    </CFormLabel>
                                    <PlacesAutocomplete
                                      value={tripFrom}
                                      onChange={(data) => {
                                        console.log(data);
                                        setTripFrom(data);
                                        setSelectedFrom(false);
                                        if (data < 1) {
                                          setErrors({
                                            ...errors,
                                            trip_from:
                                              "Please add valid trip from address",
                                          });
                                        } else {
                                          setErrors({ ...errors, trip_from: null });
                                        }
                                      }}
                                      onSelect={handleSelectTripFrom}
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div>
                                          <CFormInput
                                            id="inputtripfrom"
                                            {...getInputProps({
                                              placeholder: "Enter a location",
                                            })}
                                          />
                                          <div className="suugestion-div">
                                            <div className="suggestion-inner">
                                              {loading && <div>Loading...</div>}
                                              {suggestions
                                                .slice(0, 3)
                                                .map((suggestion) => (
                                                  <div
                                                    key={suggestion.id}
                                                    {...getSuggestionItemProps(
                                                      suggestion
                                                    )}
                                                  >
                                                    {suggestion.description}
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                    {(errors.trip_from || !selectedFrom) && (
                                      <span
                                        style={{ color: "red" }}
                                        className="text-danger"
                                      >
                                        {errors.trip_from}
                                      </span>
                                    )}
                                  </CCol>
                                  <CCol xs={6}>
                                    <CFormLabel htmlFor="inputtripto">
                                      Trip To
                                    </CFormLabel>
                                    <PlacesAutocomplete
                                      value={tripTo}
                                      onChange={(data) => {
                                        console.log(data);
                                        setTripTo(data);
                                        setSelectedTo(false);
                                        // if (data < 1 || !setSelectedTo) {
                                        setErrors({
                                          ...errors,
                                          trip_to:
                                            "Please select valid trip to address",
                                        });
                                        // } else {
                                        // setErrors({ ...errors, trip_to: null });
                                        // }
                                      }}
                                      onSelect={handleSelectTripTo}
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div>
                                          <CFormInput
                                            id="inputtripto"
                                            {...getInputProps({
                                              placeholder: "Enter a location",
                                            })}
                                          />
                                          <div className="suugestion-div">
                                            <div className="suggestion-inner">
                                              {loading && <div>Loading...</div>}
                                              {suggestions
                                                .slice(0, 3)
                                                .map((suggestion) => (
                                                  <div
                                                    key={suggestion.id}
                                                    {...getSuggestionItemProps(
                                                      suggestion
                                                    )}
                                                  >
                                                    {suggestion.description}
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                    {errors.trip_to && (
                                      <span
                                        style={{ color: "red" }}
                                        className="text-danger"
                                      >
                                        {errors.trip_to}
                                      </span>
                                    )}
                                   </CCol>
                                  <CCol xs={6}>
                                    <CFormLabel htmlFor="inputtripfrom">
                                      Price: {price} (€)
                                    </CFormLabel>
                                  </CCol>
                                  <CCol xs={12}>
                                    <div
                                      className="d-flex justify-content-end"
                                      style={{ marginTop: "40px" }}
                                    >
                                      <CButton
                                        type="button"
                                        className="confirm_boking_btn"
                                        onClick={adddata}
                                      >
                                        Calculate
                                      </CButton>
                                    </div>
                                  </CCol>
                                </CForm>
                              </CCardBody>
                            </CCard>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {step === 2 && (
                <section
                  className="booking-section-form"
                  style={{ width: "100%" }}
                >
                  <div
                    className="booking-banner"
                    id="bookimg-header"
                    style={{
                      backgroundColor: "pink",
                    }}
                  >
                    <div
                      className="row booking-content col-md-12"
                      style={{ background: "#fdbd1b" }}
                    >
                      <div className="banner-inner-text col-md-6">
                        <h3>2. Your Fare </h3>

                        <CRow>
                          <CCol xs={12}>
                            <CCard>
                              <CCardBody style={{ textAlign: "left" }}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <p className="trip_from"> From : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{tripFrom}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> To : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{tripTo}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from">
                                      {" "}
                                      Pick up date and Time :{" "}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{pickupDate}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> Type : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">
                                      {inputData.vehicle_type}{" "}
                                      {inputData.passenger} Person
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> Price : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{price} (€)</p>
                                  </div>
                                </div>
                                <div className="next_back mt-4">
                                  <button
                                    className="confirm_boking_btn"
                                    onClick={() => setStep(1)}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="confirm_boking_btn mx-3"
                                    onClick={() => setStep(3)}
                                  >
                                    Next
                                  </button>
                                </div>
                              </CCardBody>
                            </CCard>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {step === 3 && (
                <section
                  className="booking-section-form"
                  style={{ width: "100%" }}
                >
                  <div
                    className="booking-banner"
                    id="bookimg-header"
                    style={{
                      backgroundColor: "pink",
                    }}
                  >
                    <div
                      className="row booking-content col-md-12"
                      style={{ background: "#fdbd1b" }}
                    >
                      <div className="banner-inner-text col-md-6">
                        <h3>3. Contact Information</h3>
                        <CCard>
                          <CCardBody style={{ textAlign: "left" }}>
                            <form onSubmit={formik.handleSubmit} noValidate>
                              <div>
                                <MDBRow>
                                  <MDBCol col="12" md="6">
                                    <div className="mb-4">
                                      <label
                                        htmlFor="fname"
                                        className="form-label"
                                      >
                                        First Name{" "}
                                        <span className="asterisk-mark">*</span>
                                      </label>
                                      <MDBInput
                                        id="fname"
                                        type="text"
                                        size="lg"
                                        value={formik.fname}
                                        {...formik.getFieldProps("fname")}
                                        maxLength="50"
                                        name="fname"
                                        autoComplete="off"
                                      />
                                      {formik.errors.fname &&
                                      formik.touched.fname ? (
                                        <div className="text-danger text-start">
                                          {formik.errors.fname}
                                        </div>
                                      ) : null}
                                    </div>
                                  </MDBCol>

                                  <MDBCol col="12" md="6">
                                    <div className="mb-4">
                                      <label
                                        htmlFor="phoneNumber"
                                        className="form-label"
                                      >
                                        Phone Number{" "}
                                        <span className="asterisk-mark">*</span>
                                      </label>
                                      <MDBInput
                                        id="phoneNumber"
                                        type="number"
                                        size="lg"
                                        value={formik.phoneNo}
                                        {...formik.getFieldProps("phoneNo")}
                                        maxLength="50"
                                        name="phoneNo"
                                        autoComplete="off"
                                      />
                                      {formik.errors.phoneNo &&
                                      formik.touched.phoneNo ? (
                                        <div className="text-danger text-start">
                                          {formik.errors.phoneNo}
                                        </div>
                                      ) : null}
                                    </div>
                                  </MDBCol>
                                  <MDBCol col="12" md="12">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="emailAddress"
                                        className="form-label"
                                      >
                                        Email Address{" "}
                                        <span className="asterisk-mark">*</span>
                                      </label>
                                      <MDBInput
                                        id="emailAddress"
                                        type="text"
                                        size="lg"
                                        value={formik.email}
                                        {...formik.getFieldProps("email")}
                                        maxLength="50"
                                        name="email"
                                        autoComplete="off"
                                      />
                                      {formik.errors.email &&
                                      formik.touched.email ? (
                                        <div className="text-danger text-start">
                                          {formik.errors.email}
                                        </div>
                                      ) : null}
                                    </div>
                                  </MDBCol>
                                  <MDBCol col="12" md="12">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="phoneNumber"
                                        className="form-label"
                                      >
                                        Comment{" "}
                                        <span className="asterisk-mark">*</span>
                                      </label>
                                      <MDBTextArea
                                        id="comment"
                                        type="text"
                                        size="lg"
                                        value={formik.comment}
                                        {...formik.getFieldProps("comment")}
                                        maxLength="50"
                                        name="comment"
                                        autoComplete="off"
                                      />
                                      {formik.errors.comment &&
                                      formik.touched.comment ? (
                                        <div className="text-danger text-start">
                                          {formik.errors.comment}
                                        </div>
                                      ) : null}
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <div className="next_back mt-3">
                                  <button
                                    className="confirm_boking_btn"
                                    onClick={() => setStep(2)}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="confirm_boking_btn mx-3"
                                    onClick={() => setStep(4)}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </form>
                          </CCardBody>
                        </CCard>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {step === 4 && (
                <section
                  className="booking-section-form"
                  style={{ width: "100%" }}
                >
                  <div
                    className="booking-banner"
                    id="bookimg-header"
                    style={{
                      backgroundColor: "pink",
                    }}
                  >
                    <div
                      className="row booking-content col-md-12"
                      style={{ background: "#fdbd1b" }}
                    >
                      <div className="banner-inner-text col-md-6">
                        <h3>4. Order </h3>

                        <CRow>
                          <CCol xs={12}>
                            <CCard>
                              <CCardBody style={{ textAlign: "left" }}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <p className="trip_from"> From : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{tripFrom}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> To : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{tripTo}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from">
                                      {" "}
                                      Pick up date and Time :{" "}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{pickupDate}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> Price : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">{price} (€)</p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> Type : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">
                                      {inputData.vehicle_type}{" "}
                                      {inputData.passenger} Person
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> Name : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">
                                      {formik.values.fname}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from">
                                      {" "}
                                      Phone number :{" "}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">
                                      {formik.values.phoneNo}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_from"> Email : </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="trip_detail">
                                      {formik.values.email}
                                    </p>
                                  </div>
                                </div>

                                <div className="next_back mt-4">
                                  <button
                                    className="confirm_boking_btn"
                                    onClick={() => setStep(3)}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="confirm_boking_btn mx-3"
                                    onClick={() => submitFormData()}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </CCardBody>
                            </CCard>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </>
          )}
          <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
          >
            <CModalBody>
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4 delete_vehicle_popup">
                    <CCardBody>
                      <img
                        src={SuccessIcon}
                        alt="success"
                        className="success_icon"
                      />
                      <p className="mt-3 mb-0 success_title">
                        Your Trip Successfully Booked
                      </p>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CModalBody>
          </CModal>
        </div>
      </div>
    </div>
  );
};

export default Newvisits;
