import React from 'react'
import SidebarDriver from '../Sidebar';
import AppHeader from '../../TopBar/AppHeader';
import AccessAllocateMap from './AccessAllocateMap';
import backtovehicle from "../../../assets/images/left-arrow.png";
import {  useNavigate, useParams } from "react-router";
import { Link } from 'react-router-dom';


const AccessAllocateDriver = () => {

    const { company_id, id } = useParams();

  return (
    <>
     <div className="container-fluidd">
                <div className="col-md-12">
                    <div>
                        <SidebarDriver />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div className="body flex-grow-1 px-3">
                                <h1 className="heading-for-every-page"><Link to={`/access-trips/${company_id}`}>
                    <img src={backtovehicle} alt="edit" />Access Allocate Trip
                  </Link></h1>
                                <AccessAllocateMap/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default AccessAllocateDriver