import deletepopup from "../../../assets/images/deletepopup.png";
import React, { useEffect, useState } from "react";
import AppHeader from "../../TopBar/AppHeader";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Switch from "react-switch";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CCardBody,
  CRow,
  CCard,
  CCol,
  CFormInput,
  CFormLabel,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { toast } from "react-toastify";
import editiconimg from "../../../assets/images/editicon.png";
import deleteiconimg from "../../../assets/images/deleteicon.png";
import { deleteSuperAdmin, editSuperAdmin, getSuperAdminList } from "../../../utils/api";
import AppLoader from "../../AppLoader";
import SuperAdminSideBar from "../Sidebar/SideBar";
import { MDBInputGroup, MDBInput, MDBIcon } from "mdb-react-ui-kit";
import EmptyData from "../../EmptyData";
import Modal from "react-bootstrap/Modal";

const AllSuperAdmins = () => {

  const navigate = useNavigate();
  const [admin_list, setAdminList] = useState([]);
  const [search, setSearch] = useState(null);
  const [to_delete, setToDelete] = useState(null);
  const [to_update, setToUpdate] = useState({ id: null, status: false });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(3);
  const [maxPage, setMaxPage] = React.useState(3);
  const [minPage, setMinPage] = React.useState(0);
  const recordPage = 10;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const data = admin_list?.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(admin_list?.length / recordPage);
  const number = [...Array(nPage + 1).keys()].slice(1);


  const pageNumber = number.map((num, i) => {
    if (num < maxPage + 1 && num > minPage) {
      return (
        <>
          <li
            key={i}
            className={currentPage == num ? `active_btn ` : `unactive_btn`}
          >
            <button onClick={() => changePage(num)}>{num}</button>
          </li>
        </>
      );
    } else {
      return null;
    }
  });

  const handlePrePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      if ((currentPage - 1) % pageLimit == 0) {
        setMaxPage(maxPage - pageLimit);
        setMinPage(minPage - pageLimit);
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
      if (currentPage + 1 > maxPage) {
        setMaxPage(maxPage + pageLimit);
        setMinPage(minPage + pageLimit);
      }
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  let pageIncreament = null;
  if (data.length > maxPage) {
    pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
  }

  useEffect(() => {
    apiCaller();
  }, []);

  const searchData = () => {

  }

  const apiCaller = () => {
    console.log("hellooo tehere")
    setLoading(true);
    getSuperAdminList().then((res) => {
      console.log(res, "admin_list");
      if (res?.data?.code === 200) {
        setAdminList(res?.data?.data);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const deleteAdminHandler = async (id) => {
    try {
      const response = await deleteSuperAdmin(id);
      setToDelete(null)
      if (response.data.code === 200) {
        toast.success(`${response.data.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
        apiCaller()
      } else {
        toast.success(`${response.data.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log(error);
      setToDelete(null)
    }
  };

  const handleEdit = (id) => {
    navigate(`/super-admin/edit-super-admin/${id}`);
  };

  const handleStatusChange = async (id, status) => {
    try {
      let response = await editSuperAdmin({ status: status }, id);
      setToUpdate({ id: null, status: false })
      if (response.data.code === 200) {
        toast.success(`${response.data.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
        apiCaller()
      } else {
        toast.success(`${response.data.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log(error);
      setToUpdate({ id: null, status: false })
    }
  };

  return (
    <>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <SuperAdminSideBar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div className="body flex-grow-1 px-3">
                <h1 className="heading-for-every-page">Super Admins</h1>
                {/* <div className="serach-left" id="company-search">
                  <MDBInputGroup>
                    <MDBInput
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Search"
                    />
                    <button className="search-btn" onClick={searchData}>
                      <MDBIcon icon="search" />
                    </button>
                  </MDBInputGroup>
                </div> */}
                <div className="active-trip-outer">
                  {loading ? (
                    <AppLoader />
                  ) : (
                    <>
                      {data.length > 0 ? (
                        <CTable
                          align="middle"
                          className="mb-0"
                          hover
                          responsive
                        >
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell className="text-center">
                                S. No.
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">
                                Admin ID
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">
                                Name
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">
                                Email
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">
                                Phone
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">
                                Status
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">
                                Action
                              </CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {data?.map((item, index) => (
                              <CTableRow
                                className="text-center"
                                v-for="item in tableItems"
                                key={item._id}
                              >
                                <CTableDataCell>
                                  <div>{firstIndex + index + 1}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?._id}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {item.first_name + " " + item.last_name}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.email}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.phone}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <Switch
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={18}
                                    width={35}
                                    onChange={() => {
                                      setToUpdate({ id: item._id, status: item?.status === false ? true : false })
                                    }}
                                    checked={item?.status == false ? item?.status : true}
                                  />
                                </CTableDataCell>
                                <CTableDataCell className="text-center company-list-icons">
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      marginRight: "10px"
                                    }}
                                    onClick={() => handleEdit(item._id)}
                                  >
                                    <img
                                      src={editiconimg}
                                      alt="edit"

                                    />
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      marginRight: "10px"
                                    }}
                                    onClick={() => {
                                      setToDelete(item._id);
                                    }}
                                  >
                                    <img src={deleteiconimg} alt="delet" />
                                  </button>
                                </CTableDataCell>
                              </CTableRow>
                            ))}
                          </CTableBody>
                        </CTable>
                      ) : (
                        <EmptyData />
                      )}
                    </>
                  )}
                  {data?.length > 0 ? (
                    <div
                      className="pagination-outer"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="prev_btn"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <button onClick={() => handlePrePage()}>
                          Previous
                        </button>
                      </div>
                      <div className="previous-page">
                        <ul>
                          {pageNumber}
                          <button className="dots_btn">{pageIncreament}</button>
                        </ul>
                      </div>
                      <div className="next_btn">
                        <button onClick={() => handleNextPage()}>Next</button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* delete admin modal */}
                  <CModal
                    alignment="center"
                    visible={!!to_delete}
                    onClose={() => setToDelete(null)}
                  >
                    <CModalBody>
                      <CRow>
                        <CCol xs={12}>
                          <CCard className="mb-4 delete_vehicle_popup">
                            <CCardBody>
                              <img src={deletepopup} alt="danger" />
                              <h2>Are you Sure</h2>
                              <p>You want to delete this Super Admin ?</p>
                            </CCardBody>
                            <div className="delete_vehicle_popup_outer">
                              <CButton
                                className="delete_popup"
                                onClick={() => {
                                  deleteAdminHandler(to_delete);
                                }}
                              >
                                Delete
                              </CButton>
                              <CButton
                                className="cancel_popup"
                                onClick={() => {
                                  setToDelete(null);
                                }}
                              >
                                Cancel
                              </CButton>
                            </div>
                          </CCard>
                        </CCol>
                      </CRow>
                    </CModalBody>
                  </CModal>
                  {/* change status modal */}
                  <CModal
                    alignment="center"
                    visible={!!to_update.id}
                    onClose={() => setToUpdate({ id: null, status: false })}
                  >
                    <CModalBody>
                      <CRow>
                        <CCol xs={12}>
                          <CCard className="mb-4 delete_vehicle_popup">
                            <CCardBody>
                              <img src={deletepopup} alt="danger" />
                              <h2>Are you Sure</h2>
                              <p>You want to {to_update.status == false ? "Enable" : "Block"} this Super Admin ?</p>
                            </CCardBody>
                            <div className="delete_vehicle_popup_outer">
                              <CButton
                                className="delete_popup"
                                onClick={() => {
                                  handleStatusChange(to_update.id , to_update.status);
                                }}
                              >
                                Confirm
                              </CButton>
                              <CButton
                                className="cancel_popup"
                                onClick={() => {
                                  setToUpdate({ id: null, status: false })
                                }}
                              >
                                Cancel
                              </CButton>
                            </div>
                          </CCard>
                        </CCol>
                      </CRow>
                    </CModalBody>
                  </CModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllSuperAdmins;
